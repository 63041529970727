<template>
  <v-app>
    <v-app-bar app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <v-img
          alt="Glovo Logo"
          contain
          class="app-bar-logo"
          src="https://res.cloudinary.com/glovoapp/image/fetch///https://glovoapp.com/images/glovo-white.svg"
          transition="scale-transition"
        />
      </div>

      <v-spacer></v-spacer>

      <template v-slot:extension v-if="includes(PaCallAdminTabs)">
        <v-tabs centered slider-color="#ffc244" v-model="router">
          <v-tab
            to="/PaCallAdmin/PaCall"
            v-if="includes(PaCallAdminTabs)"
            key="PaCall"
            >PaCall</v-tab
          >
          <v-tab
            to="/PaCallAdmin/HubManagement"
            v-if="includes(PaCallAdminTabs)"
            key="HubManagement"
            >HubManagement</v-tab
          >
        </v-tabs>
      </template>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      bottom
      temporary
      class="notranslate"
      v-if="isAuthenticated"
    >
      <v-list nav dense>
        <v-list-item-group v-model="group" active-class="text--accent-4">
          <router-link to="/">
            <v-list-item>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/databaseLogin">
            <v-list-item>
              <v-list-item-title>Database Login</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/userList" v-if="checkScopes(1)">
            <v-list-item>
              <v-list-item-title>Database Admin</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link v-if="checkScopes(2)" to="/PartnerPEP">
            <v-list-item>
              <v-list-item-title>PartnerPEP</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link v-if="checkScopes(6)" to="/PaCallAdmin/PaCall">
            <v-list-item>
              <v-list-item-title>PaCall</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link
            v-if="checkScopes(6)"
            to="/AgentsTicketGeneratorAdmin/PaCall"
          >
            <v-list-item>
              <v-list-item-title>AgentsTicketGenerator</v-list-item-title>
            </v-list-item>
          </router-link>

          <router-link to="/logout">
            <v-list-item>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import store from "./store";

export default {
  name: "App",

  data: () => ({
    router: "",
    drawer: false,
    group: null,
    homeLink: "/",
    TechOpsAdminTabs: ["Home", "UserList", "DatabaseLogin"],
    PaCallAdminTabs: ["PaCall", "HubManagement"],
    isAuthenticated: store.getters.isAuthenticated,
  }),
  methods: {
    checkScopes(scope) {
      return store.getters.Scopes.includes(scope);
    },
    includes(array) {
      if (array.includes(this.$route.name)) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat");

#app {
  font-family: "Montserrat";
}

h1 {
  font-family: inherit;
  font-size: 2em !important;
  text-align: center;
}

a {
  color: #333;
  text-decoration: none;
}
</style>
